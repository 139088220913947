body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Flex containers*/

.app, .grid {
    /*width: 100%;*/
    height: 100%;
}

    .app .top, .grid .top {
        border-bottom: 1px solid #eee;
    }

    .grid .top {
        padding: 14px 14px 7px 14px;
    }

    .app .top .logo,
    .app .left,
    .app .right {
        width: 225px;
    }

    .app .breadcrumb {
        width: 100%;
    }

    .app .content {
        width: 100%;
    }

    .app .left,
    .app .right {
        height: 100%;
    }

    .app .left {
        border-right: 1px solid #ddd;
    }

    .app .right {
        border-left: 1px solid #ddd;
    }
