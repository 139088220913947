.swatch-check-box-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.swatch-check-box-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .swatch-check-box {
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
    border: 1px solid rgb(200, 198, 196);
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    /* cursor: pointer; */
    position: relative;
}

.swatch-check-box-container:hover input ~ .swatch-check-box, 
.swatch-check-box-container input:checked ~ .swatch-check-box {
    border: 4px solid rgb(243, 242, 241);
}

.swatch-check-box-container:hover input:checked ~ .swatch-check-box:before {
    content: "";
    height: 32px;
    width: 32px;
    position: absolute;
    left: -4px;
    top: -4px;
    border-radius: 50%;
    box-shadow: rgb(96, 94, 92) 0px 0px 0px 1px inset;
}